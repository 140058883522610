import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-value-type',
  templateUrl: './select-value-type.component.html',
  styleUrls: ['./select-value-type.component.scss']
})
export class SelectValueTypeComponent implements OnDestroy {

  public items: any[] = [
    { value: '', name: 'Margen $' },
    { value: 'percent', name: 'Margen %' }
  ];

  public valueType: any = '';
  valueType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.valueType$ = sharedService.sharedValueType.subscribe((valueType: string) => {
      if (this.valueType !== valueType) {
        this.valueType = valueType;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.valueType$.unsubscribe();
  }

  changeVariable(event: any) {
    this.valueType = event;
    this.sharedService.nextValueType = this.valueType;
  }

}
